@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0a192f;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8892b0;
  border-radius: 5px;
}
